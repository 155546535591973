import React from 'react';
import { graphql } from 'gatsby';
import { getCategorisedIssues, getPublishedIssues } from '../model/issue';
import { Layout } from '../components/layout';
import { HeadMeta } from '../components/layout/meta';

const IndexPage = ({
  data: {
    wpPage: { seo },
    wp: {
      siteOptionsMetaboxSettings: {
        optionsIssuesAmount: issuesAmount,
        optionsIssuesAmountNumber: issuesAmountToDisplay,
        optionsIssuesHomepage: issueIds,
      },
    },
    allWpTghpTaxonomyIssue: { nodes: allIssues },
  },
}) => {
  let publishedIssues = getPublishedIssues(issueIds, allIssues);
  if (issuesAmount === 'amount' && Number(issuesAmountToDisplay) !== 0) {
    publishedIssues = getPublishedIssues(issueIds, allIssues).slice(0, issuesAmountToDisplay);
  }

  const displayIssues = getCategorisedIssues(publishedIssues);

  return (
    <Layout location={'home'} seoData={seo}>
      <div className="issue-grid">{displayIssues}</div>
    </Layout>
  );
};

export const query = graphql`
  {
    wpPage(isFrontPage: { eq: true }) {
      ...SeoData
    }

    wp {
      ...SiteOptions
    }

    allWpTghpTaxonomyIssue {
      nodes {
        ...AllIssues
      }
    }
  }
`;

export default IndexPage;

export const Head = () => <HeadMeta />;
